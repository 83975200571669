import { Box } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const StyledSigninContainer = styled(Box)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledFormContainer = styled(Box)`
  margin-top: 25px;
  width: 100%;
`

export const StyledPasswordContainer = styled(Box)(({ theme }) => ({
  fontFamily: `${theme.typography.fontFamily}`,
  color: `${theme.palette['grey'][500]}`,
  marginTop: '5px',
  width: '100%',
}))

export const StyledLink = styled('a')(({ theme }) => ({
  color: `${theme.palette['primary']['main']}`,
  textDecoration: 'underline',
  '&:hover': {
    color: `${theme.palette['primary']['main']}`,
  },
  '&:focus': {
    color: `${theme.palette['primary']['main']}`,
  },
  '&:visited': {
    color: `${theme.palette['primary']['main']}`,
  },
}))

export const StyledInputField = styled(TextField)`
  margin-top: 5px;
`

export const StyledInputLabel = styled(InputLabel)`
  font-weight: 700;
`
