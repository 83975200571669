import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './app/app'

declare let __webpack_public_path__: string | undefined

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <App publicPath={__webpack_public_path__} />
    </BrowserRouter>
  </StrictMode>
)
