import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material/styles/createTypography'
import { styled } from '@mui/system'

export const StyledLegalCopy = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: '16px',
  fontFamily: `${(theme.typography as Typography).fontFamily}`,
  color: `${theme.palette['grey'][500]}`,
  marginTop: '40px',
}))

const LegalCopy = () => (
  <FormattedMessage id="signin.copyright_txt" values={{ year: new Date().getFullYear() }}>
    {(txt) => <StyledLegalCopy>{txt}</StyledLegalCopy>}
  </FormattedMessage>
)

export default LegalCopy
