import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ForgotPasswordAttributes } from '../../models/auth.model'
import { StyledFormContainer, StyledInputLabel } from '../common/styled/StyledComponents'

const initialFormValues = {
  email: '',
}

export type ForgotPasswordFormProps = {
  onSubmit: (data: ForgotPasswordAttributes, helpers: FormikHelpers<ForgotPasswordAttributes>) => void
}

export const ForgotPasswordForm = ({ onSubmit }: ForgotPasswordFormProps) => {
  const intl = useIntl()

  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required()
      .label(intl.formatMessage({ id: 'forgot_password.email_label' })),
  })

  return (
    <StyledFormContainer data-testid="container">
      <Formik initialValues={initialFormValues} onSubmit={onSubmit} validationSchema={ForgetPasswordSchema}>
        {({ handleChange, isValid, submitForm }) => (
          <Stack component={Form} spacing={2}>
            <StyledInputLabel htmlFor="email">
              <FormattedMessage id="forgot_password.email_label" />
            </StyledInputLabel>
            <Field
              component={TextField}
              name="email"
              id="email"
              fullWidth
              autoComplete="email"
              placeholder={intl.formatMessage({
                id: 'forgot_password.email_placeholder',
              })}
              autoFocus
            />

            <Button
              type="submit"
              id="forgot-submit"
              fullWidth
              variant="contained"
              disabled={!isValid}
              onClick={submitForm}
            >
              <FormattedMessage id={'forgot_password.reset_button'} />
            </Button>
          </Stack>
        )}
      </Formik>
    </StyledFormContainer>
  )
}
