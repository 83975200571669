import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useCookie } from 'react-use'
import { ThemeProvider } from '@mui/material/styles'
import flatten from 'flat'

import { YupErrorMapProvider } from '@lib/forms'
import { config, PageTitle, vantageTheme } from '@lib/ui-shared'
import { ForgotPasswordPage } from '@login/routes/ForgotPasswordPage'

import { ForgotPasswordKey } from '../components/forgot-password/forgot-password-key'
import { SignIn } from '../components/signIn/signIn'
import * as defaultMessages from '../i18n/locales/default.json'
import { Configuration, updateConfig } from '../redux/slices/configurationSlice'
import { setupStore } from '../redux/store'
import { SignUpPage } from '../routes/SignUpPage'

const copy: any = {
  en: flatten(defaultMessages),
}

declare global {
  interface Window {
    config: any
  }
}

if (!window.config) {
  window.config = {
    ...config,
  }
}

export type AppProps = {
  publicPath: string | undefined
}

export function App({ publicPath }: AppProps) {
  const [csrfToken] = useCookie('csrftoken')
  const configurationType: Configuration = {
    publicPath,
    csrfToken: csrfToken || '',
  }

  const store = setupStore()

  store.dispatch(updateConfig(configurationType))

  return (
    <HelmetProvider>
      <IntlProvider locale="en" messages={copy['en']}>
        <YupErrorMapProvider>
          <ThemeProvider theme={vantageTheme}>
            <Provider store={store}>
              <Routes>
                <Route
                  path="auth/login"
                  element={
                    <>
                      <PageTitle title={<FormattedMessage id="signin.title" />} />
                      <SignIn />
                    </>
                  }
                />
                <Route
                  path="auth/forgot-password/:resetKey"
                  element={
                    <>
                      <PageTitle title={<FormattedMessage id="forgot_password.title" />} />
                      <ForgotPasswordKey />
                    </>
                  }
                />
                <Route
                  path="auth/forgot-password"
                  element={
                    <>
                      <PageTitle title={<FormattedMessage id="forgot_password.title" />} />
                      <ForgotPasswordPage />
                    </>
                  }
                />
                <Route
                  path="auth/signup"
                  element={
                    <>
                      <PageTitle title={<FormattedMessage id="signup.title" />} />
                      <SignUpPage />
                    </>
                  }
                />
                <Route path="*" element={<Navigate to={'/auth/login'} />} />
              </Routes>
            </Provider>
          </ThemeProvider>
          {/* END: routes */}
        </YupErrorMapProvider>
      </IntlProvider>
    </HelmetProvider>
  )
}

export default App
