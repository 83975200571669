import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { API_V1_BASE_URL } from '@lib/ui-shared'
import { RootState } from '@login/redux/store'

export const csrfBaseQuery = fetchBaseQuery({
  baseUrl: API_V1_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const state: RootState = getState() as RootState
    headers.set('X-CSRFToken', state.configuration.csrfToken || '')
    return headers
  },
})
