import { CSSProperties, ForwardedRef, forwardRef, KeyboardEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ArrowForwardIosOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik'

import { AuthAttributes } from '../../models/auth.model'
import { StyledInputField, StyledInputLabel } from '../common/styled/StyledComponents'

export type EmailProps = {
  setActive: (value: boolean) => void
  style?: CSSProperties
}

const EmailContainer = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

export const Email = forwardRef(({ setActive, style }: EmailProps, ref: ForwardedRef<HTMLDivElement>) => {
  const intl = useIntl()
  const { values, errors, handleChange } = useFormikContext<AuthAttributes>()

  const checkEmail = () => {
    if (!errors.email && values.email.length > 0) {
      setActive(false)
    }
  }

  const checkEmailOnEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      checkEmail()
    }
  }

  return (
    <EmailContainer spacing={1} ref={ref} style={style}>
      <FormattedMessage id="signin.email_placeholder">
        {(txt) => <StyledInputLabel>{txt}</StyledInputLabel>}
      </FormattedMessage>
      <StyledInputField
        margin="normal"
        required
        fullWidth
        id="id_email"
        data-testid="signin-email"
        name="email"
        type="email"
        placeholder={intl.formatMessage({
          id: 'signin.email_placeholder',
        })}
        value={values.email}
        onChange={handleChange}
        onBlur={() => checkEmail()}
        onKeyPress={checkEmailOnEnter}
        autoComplete="email"
        autoFocus
      />
      {errors.email && <Alert severity="error">{errors.email}</Alert>}
      {!errors.email && values.email.length > 0 && (
        <IconButton onClick={() => setActive(false)} sx={{ alignSelf: 'flex-end' }}>
          <ArrowForwardIosOutlined fontSize="large" />
        </IconButton>
      )}
    </EmailContainer>
  )
})
