import { useSearchParams } from 'react-router-dom'

export const useCoBrand = () => {
  const [searchParams] = useSearchParams()

  const cobrandedTHDDomainsCA: string[] = [
    'ca.thdretailmedia.com',
    'ca.orangeapronmedia.com',
    'ca-stg.orangeapronmedia.com',
    'ca-rls.orangeapronmedia.com',
  ]

  const cobrandedTHDDomainsUS: string[] = [
    'us.thdretailmedia.com',
    'us.orangeapronmedia.com',
    'us-stg.orangeapronmedia.com',
    'us-rls.orangeapronmedia.com',
  ]

  const domain = new URL(window.location.href).host
  const isHdca = searchParams.get('cb') === 'hdca' || cobrandedTHDDomainsCA.includes(domain)
  const isHdus = searchParams.get('cb') === 'hdus' || cobrandedTHDDomainsUS.includes(domain)
  const isUlta = searchParams.get('cb') === 'ulta'

  return [searchParams, { isHdca, isHdus, isUlta }] as const
}
