import { Fragment, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, Button, Grid, InputAdornment, Typography } from '@mui/material'
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { MIN_PASSWORD_LENGTH, PasswordRequirements } from '../features/passwords'
import { LOWERCASE, NUMBERS, SYMBOLS, UPPERCASE } from '../utils/user.utils'

import { StyledLink, StyledPasswordContainer } from './common/styled/StyledComponents'

export type SignUpFormFields = {
  firstName: string
  lastName: string
  email: string
  password1: string
  terms: boolean
  termsHdca?: boolean
}

const initialFormValues: SignUpFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  password1: '',
  terms: false,
  termsHdca: false,
}

export interface SignUpFormProps {
  isHdca: boolean
  onSubmit: (values: SignUpFormFields, formikHelpers: FormikHelpers<SignUpFormFields>) => void
}

export function SignUpForm({ isHdca, onSubmit }: SignUpFormProps) {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const SignUpSchema = useMemo(() => {
    const BaseSchema = Yup.object<SignUpFormFields>().shape({
      firstName: Yup.string()
        .required()
        .label(intl.formatMessage({ id: 'signup.firstName' })),
      lastName: Yup.string()
        .required()
        .label(intl.formatMessage({ id: 'signup.lastName' })),
      email: Yup.string()
        .email()
        .required()
        .label(intl.formatMessage({ id: 'signup.email' })),

      password1: Yup.string()
        .min(MIN_PASSWORD_LENGTH)
        .matches(UPPERCASE)
        .matches(LOWERCASE)
        .matches(NUMBERS)
        .matches(SYMBOLS)
        .required()
        .label(intl.formatMessage({ id: 'signup.password' })),

      terms: Yup.boolean()
        .oneOf([true], intl.formatMessage({ id: 'form_errors.terms_required' }))
        .required()
        .label(intl.formatMessage({ id: 'signup.terms_of_service' })),
    })

    const HdcaSchema = BaseSchema.concat(
      Yup.object({
        termsHdca: Yup.boolean()
          .oneOf([true], intl.formatMessage({ id: 'form_errors.terms_required_hdca' }))
          .required()
          .label(intl.formatMessage({ id: 'signup.terms_of_service_hdca' })),
      })
    )

    return isHdca ? HdcaSchema : BaseSchema
  }, [intl, isHdca])

  const renderTermsErrors = (errors: FormikErrors<SignUpFormFields>) => {
    const messages = Object.entries(errors)
      .filter(([path]) => path === 'terms' || path === 'termsHdca')
      .map(([, message]) => <Typography>{message}</Typography>)

    return errors.terms || errors.termsHdca ? (
      <Alert severity="error">
        {messages.map((message, index) => (
          <Fragment key={index}>{message}</Fragment>
        ))}
      </Alert>
    ) : null
  }

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit} validationSchema={SignUpSchema}>
      {({ values, handleChange, isSubmitting, errors, submitCount, isValid }) => {
        const hasSubmitted = submitCount > 0

        return (
          <Grid container spacing={1} component={Form} noValidate data-testid="sign-up-form-container">
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="firstName"
                id="firstName"
                fullWidth
                label={intl.formatMessage({
                  id: 'signup.firstName',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="lastName"
                id="lastName"
                fullWidth
                label={intl.formatMessage({
                  id: 'signup.lastName',
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                type="email"
                name="email"
                id="email"
                label={intl.formatMessage({ id: 'signup.email' })}
                autoComplete="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="password1"
                id="password1"
                type={showPassword ? 'text' : 'password'}
                label={intl.formatMessage({ id: 'signup.password' })}
                // We track changes in this field for the password requirements component
                onChange={handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={'cursor-pointer'}
                      data-testid={'show-hide-password'}
                      position="end"
                      // TODO: add a11y label
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                }}
              />
              <StyledPasswordContainer>
                {/* TODO: move PasswordRequirements into `forms` lib */}
                <PasswordRequirements password={values.password1} />
              </StyledPasswordContainer>
            </Grid>

            {isHdca && (
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="termsHdca"
                  Label={{
                    label: (
                      <StyledLink href="https://gotvantage.com/additional-privacy-policies/" target="_blank">
                        <FormattedMessage id="signup.terms_of_service_hdca" />
                      </StyledLink>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="terms"
                Label={{
                  label: (
                    <StyledLink href="https://gotvantage.com/terms-of-use/" target="_blank">
                      <FormattedMessage id="signup.terms_of_service" />
                    </StyledLink>
                  ),
                }}
              />
            </Grid>

            {hasSubmitted && !isValid ? (
              <Grid item xs={12}>
                {renderTermsErrors(errors)}
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Button variant="contained" type="submit" fullWidth disabled={isSubmitting}>
                <FormattedMessage id="signup.register_button" />
              </Button>
            </Grid>
          </Grid>
        )
      }}
    </Formik>
  )
}

export default SignUpForm
