import { useEffect, useState } from 'react'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { PasswordRequirements } from '@login/features/passwords'

import { ForgotPasswordResetAttributes } from '../../models/auth.model'
import { useForgotPasswordKeyMutation, useForgotPasswordResetMutation } from '../../redux/apis/authApi'
import { LOWERCASE, NUMBERS, SYMBOLS, UPPERCASE } from '../../utils/user.utils'
import { Header } from '../common/header/header'
import LegalCopy from '../common/LegalCopy'
import {
  StyledFormContainer,
  StyledInputField,
  StyledInputLabel,
  StyledPasswordContainer,
  StyledSigninContainer,
} from '../common/styled/StyledComponents'

const initialFormValues = {
  password1: '',
  password2: '',
}

export const ForgotPasswordKey = () => {
  const intl = useIntl()
  const { resetKey } = useParams()
  const [validToken, setValidToken] = useState<undefined | boolean>(undefined)
  const [showPassword1, setShowPassword1] = useState<boolean>(false)
  const [showPassword2, setShowPassword2] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [err, setError] = useState<string>('')
  const [forgotPasswordKey, { isLoading }] = useForgotPasswordKeyMutation()
  const [forgotPasswordReset] = useForgotPasswordResetMutation()

  const forgetPasswordSchema = Yup.object().shape({
    password1: Yup.string().min(8).matches(UPPERCASE).matches(LOWERCASE).matches(NUMBERS).matches(SYMBOLS).required(),
    password2: Yup.string().oneOf([Yup.ref('password1')], 'Passwords must match'),
  })

  useEffect(() => {
    forgotPasswordKey(resetKey)
      .unwrap()
      .then((response: any) => {
        setValidToken(true)
      })
      .catch((error: any) => {
        setValidToken(false)
      })
  }, [forgotPasswordKey, resetKey, setValidToken])

  const handleSubmit = (
    data: ForgotPasswordResetAttributes,
    { resetForm }: FormikHelpers<ForgotPasswordResetAttributes>
  ) => {
    forgotPasswordReset({ data, resetKey: resetKey })
      .unwrap()
      .then((response: any) => {
        setMessage(
          intl.formatMessage({
            id: 'forgot_password.key.password_change_success',
          })
        )
        resetForm()
      })
      .catch((error: any) => {
        resetForm()
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <StyledSigninContainer>
        <Header />
        <Box className="mb-2">
          <Typography component="p" data-testid={'forgot-password-heading'}>
            <FormattedMessage id={validToken ? 'forgot_password.key.heading' : 'forgot_password.bad_token.heading'} />
          </Typography>
        </Box>
        {message && (
          <Stack mt={2} mb={2} data-testid="forgot_password-message">
            <Alert severity="success">
              {message}{' '}
              <Link href="/auth/login/" data-testid={'login-link'}>
                Click here
              </Link>
            </Alert>
          </Stack>
        )}
        {err && (
          <Stack mt={2} mb={2} data-testid="forgot_password-errors">
            <Alert severity="error"> {err}</Alert>
          </Stack>
        )}
        {validToken ? (
          <StyledFormContainer>
            <Formik initialValues={initialFormValues} onSubmit={handleSubmit} validationSchema={forgetPasswordSchema}>
              {({ values, handleChange, isValid, dirty, isSubmitting, submitForm }) => (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormattedMessage id="forgot_password.key.password1_label">
                      {(txt) => <StyledInputLabel>{txt}</StyledInputLabel>}
                    </FormattedMessage>
                    <StyledInputField
                      margin="normal"
                      required
                      fullWidth
                      id="password1"
                      data-testid={'password1'}
                      name="password1"
                      type={showPassword1 ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        id: 'forgot_password.key.password1_placeholder',
                      })}
                      value={values.password1}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className={'cursor-pointer'}
                            data-testid={'show-hide-password'}
                            position="end"
                            onClick={() => setShowPassword1(!showPassword1)}
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormattedMessage id="forgot_password.key.password2_label">
                      {(txt) => <StyledInputLabel>{txt}</StyledInputLabel>}
                    </FormattedMessage>
                    <StyledInputField
                      margin="normal"
                      required
                      fullWidth
                      id="password2"
                      data-testid={'password2'}
                      name="password2"
                      type={showPassword2 ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        id: 'forgot_password.key.password2_placeholder',
                      })}
                      value={values.password2}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className={'cursor-pointer'}
                            data-testid={'show-hide-password'}
                            position="end"
                            onClick={() => setShowPassword2(!showPassword2)}
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                    />
                    <StyledPasswordContainer>
                      <PasswordRequirements password={values.password1} />
                    </StyledPasswordContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <FormattedMessage id="forgot_password.key.submit_button">
                      {(txt) => (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          data-testid={'forgot-password-submit'}
                          disabled={!(isValid && dirty) || isSubmitting}
                          onClick={submitForm}
                        >
                          {txt}
                        </Button>
                      )}
                    </FormattedMessage>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </StyledFormContainer>
        ) : (
          <Box>
            <Typography component="p">
              <FormattedMessage id="forgot_password.bad_token.message" />{' '}
              <Link href="/auth/forgot-password" data-testid={'forgot-password-link'}>
                Click here
              </Link>
            </Typography>
          </Box>
        )}
        <LegalCopy />
      </StyledSigninContainer>
    </Container>
  )
}
