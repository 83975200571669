import { ImgHTMLAttributes } from 'react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/system'

import { withPublicPath } from '@lib/ui-shared'

import { useCoBrand } from '../../../hooks/useCoBrand'

const StyledImgBox = styled(Stack)({
  margin: '60px 0 15px 0',
})

export const Header = () => {
  const [, { isHdca, isHdus, isUlta }] = useCoBrand()

  const PublicImg = withPublicPath<'img', ImgHTMLAttributes<HTMLImageElement>>('img')

  const renderLogo = () => {
    if (isHdca) {
      return <PublicImg src="assets/images/hdca/login.png" width="100%" alt="The Home Depot Orange Apron Media logo" />
    }
    if (isHdus) {
      return <PublicImg src="assets/images/hdus/login.png" width="100%" alt="The Home Depot Orange Apron Media logo" />
    }
    if (isUlta) {
      return <PublicImg src="assets/images/ulta/login.png" width="100%" alt="UB Media logo" />
    }
    return <PublicImg src="assets/images/vantage-logo-login.png" width="100%" alt="The Vantage Media logo" />
  }

  return <StyledImgBox>{renderLogo()}</StyledImgBox>
}
