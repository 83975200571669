export const UPPERCASE = /[A-Z]/

export const LOWERCASE = /[a-z]/

export const NUMBERS = /\d/

export const SYMBOLS = /[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/

/** Valid symbols for password */
export const VALID_SYMBOLS = String(SYMBOLS).slice(2, -2)
