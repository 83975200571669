import { CSSProperties, ForwardedRef, forwardRef, KeyboardEvent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ArrowBackIosOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'

import { AuthAttributes } from '../../models/auth.model'
import { StyledInputField, StyledInputLabel } from '../common/styled/StyledComponents'

export type PasswordProps = {
  setActive: (value: boolean) => void
  style?: CSSProperties
}

const PasswordContainer = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

export const Password = forwardRef(({ setActive, style }: PasswordProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { values, handleChange, submitForm, isValid, dirty, isSubmitting } = useFormikContext<AuthAttributes>()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const intl = useIntl()
  const submitOnEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitForm()
    }
  }

  return (
    <PasswordContainer spacing={2} ref={ref} style={style}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={() => setActive(true)}>
          <ArrowBackIosOutlined fontSize="large" />
        </IconButton>
        <Typography variant="body1" fontWeight={700} component="span">
          {values.email}
        </Typography>
      </Stack>
      <Box>
        <FormattedMessage id="signin.password_placeholder">
          {(txt) => <StyledInputLabel>{txt}</StyledInputLabel>}
        </FormattedMessage>
        <StyledInputField
          margin="normal"
          required
          fullWidth
          name="password"
          value={values.password}
          onChange={handleChange}
          onKeyPress={submitOnEnter}
          type={showPassword ? 'text' : 'password'}
          id="id_password"
          placeholder={intl.formatMessage({
            id: 'signin.password_placeholder',
          })}
          data-testid="signin-password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment
                className="cursor-pointer"
                data-testid="show-hide-password"
                position="end"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        data-testid="signin-submit"
        disabled={!(isValid && dirty) || isSubmitting}
        onClick={submitForm}
      >
        <FormattedMessage id="signin.cta_signin" />
      </Button>
    </PasswordContainer>
  )
})
