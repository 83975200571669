import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FormikHelpers } from 'formik'

import { Header } from '@login/components/common/header/header'
import LegalCopy from '@login/components/common/LegalCopy'
import { StyledSigninContainer } from '@login/components/common/styled/StyledComponents'
import { ForgotPasswordForm } from '@login/components/forgot-password/ForgotPasswordForm'
import { ApiErrorResponse } from '@login/models/api.model'
import { ForgotPasswordAttributes } from '@login/models/auth.model'
import { useForgotPasswordMutation } from '@login/redux/apis/authApi'

export const ForgotPasswordPage = () => {
  const intl = useIntl()
  const [message, setMessage] = useState<string>('')
  const [err, setError] = useState<string>('')
  const [forgotPassword] = useForgotPasswordMutation()

  const handleSubmit = (data: ForgotPasswordAttributes, { resetForm }: FormikHelpers<ForgotPasswordAttributes>) => {
    forgotPassword(data)
      .unwrap()
      .then((response: any) => {
        setMessage(intl.formatMessage({ id: 'forgot_password.success_message' }))
        resetForm()
      })
      .catch((error: ApiErrorResponse) => {
        if (error.originalStatus === 400) {
          setError(intl.formatMessage({ id: 'forgot_password.self-serve-users' }))
        } else {
          setMessage(intl.formatMessage({ id: 'forgot_password.generic_message' }))
        }
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <StyledSigninContainer>
        <Header />
        <Stack spacing={2}>
          <Typography component="h1" variant="h5">
            <FormattedMessage id="forgot_password.title" />
          </Typography>
          <Typography component="p">
            <FormattedMessage id="forgot_password.heading" />
          </Typography>

          {message && <Alert severity="success"> {message}</Alert>}
          {err && <Alert severity="error"> {err}</Alert>}
        </Stack>

        <ForgotPasswordForm onSubmit={handleSubmit} />

        <LegalCopy />
      </StyledSigninContainer>
    </Container>
  )
}
