import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Check, Clear } from '@mui/icons-material'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

import { PasswordRule } from '../../../types/password.model'
import { LOWERCASE, NUMBERS, SYMBOLS, UPPERCASE, VALID_SYMBOLS } from '../../../utils/passwordRegExps'

export type PasswordRequirementsProps = {
  password: string
}

export const MIN_PASSWORD_LENGTH = 8

export const PasswordRequirements = ({ password }: PasswordRequirementsProps) => {
  const { formatMessage } = useIntl()

  const passwordRules: PasswordRule[] = [
    {
      name: 'characters',
      check: (password: string) => {
        return Boolean(password && password.length >= MIN_PASSWORD_LENGTH)
      },
    },
    {
      name: 'uppercase',
      check: (password: string) => {
        return Boolean(password && UPPERCASE.test(password))
      },
    },
    {
      name: 'lowercase',
      check: (password: string) => {
        return Boolean(password && LOWERCASE.test(password))
      },
    },
    {
      name: 'number',
      check: (password: string) => {
        return Boolean(password && NUMBERS.test(password))
      },
    },
    {
      name: 'symbol',
      check: (password: string) => {
        return Boolean(password && SYMBOLS.test(password))
      },
    },
  ]

  return (
    <Box>
      <Typography variant="caption">
        <FormattedMessage id="common.password-requirements.title" tagName="span" />
      </Typography>

      <List dense sx={{ p: 0 }}>
        {passwordRules.map((rule) => {
          const isRuleValid = rule.check(password)
          return (
            <ListItem
              key={rule.name}
              id={`${rule.name}-label`}
              sx={{ p: 0 }}
              data-testid={`${rule.name}-rule`}
              data-check-passed={isRuleValid}
            >
              <ListItemIcon sx={{ minWidth: 'auto', pr: 1 }}>
                {isRuleValid ? (
                  <Check color="success" aria-label={formatMessage({ id: 'common.password-requirements.passed' })} />
                ) : (
                  <Clear color="error" aria-label={formatMessage({ id: 'common.password-requirements.failed' })} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id={`common.password-requirements.${rule.name}`}
                    values={{ min: MIN_PASSWORD_LENGTH, validSymbols: VALID_SYMBOLS }}
                  />
                }
                primaryTypographyProps={{ variant: 'caption' }}
              />
            </ListItem>
          )
        })}
      </List>

      <Typography variant="caption">
        <FormattedMessage id="common.password-requirements.similarity" />
      </Typography>
    </Box>
  )
}
