import { ImgHTMLAttributes } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { withPublicPath } from '@lib/ui-shared'

const StyledContainer = styled(Box)`
  border: 2px solid #e76d2d;
  background-color: #e76d2d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
`

const HDLogo = styled('div')`
  width: 64px;
  height: 64px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HomeDepotSaml = () => {
  const PublicImg = withPublicPath<'img', ImgHTMLAttributes<HTMLImageElement>>('img')

  return (
    <StyledContainer>
      <HDLogo>
        <PublicImg src="assets/images/hdus/hd_logo.png" width="48" height="48" />;
      </HDLogo>
      <FormattedMessage id="signin.saml.home-depot" tagName="span" />
    </StyledContainer>
  )
}
