import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { FormikHelpers } from 'formik'

import SignUpForm, { SignUpFormFields } from '@login/components/SignUpForm'

import { Header } from '../components/common/header/header'
import LegalCopy from '../components/common/LegalCopy'
import { StyledFormContainer, StyledSigninContainer } from '../components/common/styled/StyledComponents'
import { useCoBrand } from '../hooks/useCoBrand'
import { SignUpAttributes } from '../models/auth.model'
import { useSignUpMutation } from '../redux/apis/authApi'

export const SignUpPage = () => {
  const intl = useIntl()
  const [, { isHdca }] = useCoBrand()
  const [errors, setErrors] = useState<string>('')
  const [authSignUp, { status }] = useSignUpMutation()

  const handleSubmit = (values: SignUpFormFields, { setSubmitting }: FormikHelpers<SignUpFormFields>) => {
    const signUpData: SignUpAttributes = { termsHdca: false, ...values }

    authSignUp(signUpData)
      .unwrap()
      .then((response: any) => {
        return
      })
      .catch((error: any) => {
        if (Object.prototype.hasOwnProperty.call(error.data, 'email')) {
          setErrors(intl.formatMessage({ id: 'form_errors.email_already_exists' }))
        } else {
          setErrors(error.data[Object.keys(error.data)[0]])
        }
      })
    setSubmitting(false)
  }

  useEffect(() => {
    if (status === 'fulfilled') {
      const nextPage = new URLSearchParams(window.location.search).get('next')
      window.location.href = nextPage || '/'
    }
  }, [status])

  return (
    <Container component="main" maxWidth="xs">
      <StyledSigninContainer>
        <Header />
        {errors && (
          <Stack mt={2} mb={2} spacing={2} data-testid="signin-errors">
            <Alert severity="error">
              {' '}
              {errors.includes('email') ? (
                <span>
                  {errors}{' '}
                  <FormattedMessage
                    id="form_errors.email_exists_login_reset"
                    values={{
                      login: (
                        <Link href="/auth/login/">
                          <FormattedMessage id="signin.cta_signin" />
                        </Link>
                      ),
                      resetPassword: (
                        <Link href="/auth/forgot-password/">
                          <FormattedMessage id="forgot_password.reset_ur_password" />
                        </Link>
                      ),
                    }}
                  />
                </span>
              ) : (
                errors
              )}{' '}
            </Alert>
          </Stack>
        )}
        <StyledFormContainer>
          <SignUpForm isHdca={isHdca} onSubmit={handleSubmit} />

          <Grid container mt={3} mb={2} justifyContent="right">
            <Grid item>
              <FormattedMessage id="signup.already_account_link">
                {(txt) => (
                  <Link href="/auth/login/" data-testid={'already-account-signin'}>
                    {txt}
                  </Link>
                )}
              </FormattedMessage>
            </Grid>
          </Grid>
        </StyledFormContainer>
        <LegalCopy />
      </StyledSigninContainer>
    </Container>
  )
}

export default SignUpPage
